<template>
  <div class="pageContent">
    <searchHeader />
    <div class="financialAccountReceive">
      <div class="jrjs_banner2"><p @click="scrollToApplication()" class="jr_banner_btn">我要申请</p></div>
      <div class="jr_cp_bg">
        <div class="wrap">
          <div class="jr_cp">
            <p>针对目前市场环境下，生产类及贸易类企业应收账款数量多、账期长的特点，交易中心与合作银行共同研发，即将推出 “应收账款融资”产品。</p>
            <div class="jrjs_tit4"></div>
            <p>“应收账款融资”产品是指交易商将其与买方订立的货物赊销合同所产生的应收账款债权转让给金融机构，由金融机构为其提供短期融资、应收账款管理等综合性服务的金融业务，以上业务全部线上进行。本产品适用于交易平台所有交易商。</p>
            <div class="jrjs_tit5"></div>
            <div class="cplist clearfix">
              <ul>
                <li class="jyljl_ico12"><a><i></i><span>融资成本低</span></a></li>
                <li class="jyljl_ico13"><a><i></i><span>操作便捷</span></a></li>
                <li class="jyljl_ico14"><a><i></i><span>有效解决资金周转</span></a></li>
              </ul>
            </div>
            <div class="jrjs_tit6"></div>
              <p>1. 融资企业、下游买方企业均为交易中心交易商，买卖双方不属于关联企业；</p>
              <p>2. 取得销售合同（交易合同）并已执行完毕。赊销合同产生应收账款债权清晰合法，未有禁止应收账款债权转让条款，未在其上设定第三人的质权和其他优先受偿权；</p>
              <p>3. 买方同意融资交易商向金融机构转让应收账款，买卖双方无产品质量等因素引发的商业纠纷；</p>
              <p>4. 以上销售交易及对应货款结算应通过交易平台完成。</p>
          </div>
        </div>  
        <div class="jr_lc"></div>
        <div class="applicationForm">
          <div class="jrjs_tit7" id="application"></div>
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="申请人姓名" prop="name">
                  <el-input v-model="form.name" placeholder="请输入申请人姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请人单位" prop="company">
                  <el-input v-model="form.company" placeholder="请输入申请人单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="phone">
                  <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品类型" prop="type">
                  <el-select class="width-100" v-model="form.type" placeholder="请选择产品类型">
                    <el-option
                      v-for="item in typeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" prop="note">
                  <el-input type="textarea" v-model="form.note" placeholder="请输入备注"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <input class="rzsq_btn" type="button" value="提交" />
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchHeader from "../../base/header/searchHeader";

export default {
  data() {
    return {
      form: {
        name: null,
        company: null,
        phone: null,
        type: null,
        note: null
      },
      rules: {
        name: [
          { required: true, message: '请输入申请人姓名', trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入申请人单位', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择产品类型', trigger: 'blur' },
        ],
        note: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ]
      },
      typeOptions: [
        {
          value: 1,
          label: '应收账款融资业务'
        },
        {
          value: 2,
          label: '应收账款融资业务'
        },
        {
          value: 3,
          label: '订单融资业务'
        },
        {
          value: 4,
          label: '仓单质押融资业务'
        }
      ]
    }
  },
  components: {
    searchHeader
  },
  methods: {
    scrollToApplication() {
      document.querySelector("#application").scrollIntoView(true);
    }
  }
}
</script>
<style scoped lang="scss">
.jrjs_banner2 {
  background: url('../../../assets/configImages/jr_banner2.jpg') no-repeat center;
  height: 203px;
  padding-top: 300px;
}
.jr_banner_btn {
  display: block;
  width: 221px;
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #fff;
  font-size: 34px;
  margin: auto;
  background: url('../../../assets/configImages/jr_banner_btn.jpg');
  cursor: pointer;
}
.jr_cp_bg {
  background: #f3f6fb;
  padding: 30px 0;
}
.wrap {
  width: 1300px;
  margin: auto;
  position: relative;
}
.jr_cp {
  padding: 30px 0;
}
.jr_cp p {
  color: #535353;
  font-size: 18px;
  line-height: 34px;
  text-indent: 2em;
}
.jrjs_tit4 {
  background: url('../../../assets/configImages/jr_tit4.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.jrjs_tit5 {
  background: url('../../../assets/configImages/jr_tit5.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.jrjs_tit6 {
  background: url('../../../assets/configImages/jr_tit6.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.cplist {
  width: 810px;
  margin: 10px auto 3px auto;
  overflow: hidden;
}
.cplist li {
  float: left;
  width: 270px;
  text-align: center;
}
.cplist li i {
  display: block;
  width: 94px;
  height: 94px;
  background: url('../../../assets/configImages/jr_icons.png');
  margin: auto;
}
.cplist .jyljl_ico12 i {
  background-position: 0 -274px;
}
.cplist .jyljl_ico13 i {
  background-position: -96px -274px;
}
.cplist .jyljl_ico14 i {
  background-position: -192px -274px;
}
.cplist li span {
  font-size: 18px;
  color: #222;
  line-height: 60px;
}
.jr_lc {
  background: url('../../../assets/configImages/jr_lc.jpg') no-repeat center;
  height: 857px;
}
// 申请表单
.applicationForm {
  width: 1300px;
  margin: auto;
  position: relative;
}
.jrjs_tit7 {
  background: url('../../../assets/configImages/jr_tit7.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
.rzsq_btn {
  display: block;
  cursor: pointer;
  border: 0;
  width: 260px;
  height: 44px;
  background: #2065cc;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 44px;
  margin: 20px auto;
  border-radius: 3px;
}
</style>